<template>
  <div class="text-center">
    <h2 class="mb-4 ">Sign In</h2>
    <v-form v-model="valid" ref="form" @submit.prevent="handleFormSubmit">

      <v-text-field
          v-model.trim="email"
          label="Enter E-mail"
          solo
          outlined
          dense
          flat
          required
          :rules="emailRules"
          class="mb-2 font-size-15"
          hide-details="auto"
          :error-messages="errors.email"
          @change="resetFormValidation"
      ></v-text-field>

      <div class="text-right text--secondary font-size-13 ">
        <router-link class="cursor-pointer" tag="span" to="recover">Forgot password?</router-link>
      </div>
      <v-text-field
          v-model.trim="password"
          label="Enter Password"
          solo
          outlined
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          dense
          flat
          required
          :rules="passwordRules"
          hide-details="auto"
          class="mb-5 font-size-15"
          :error-messages="errors.password"
          @change="resetFormValidation"
      >
      </v-text-field>

      <v-btn type="submit" class="custom--btn" height="40" color="cyan accent-4 "  dark block depressed>Sign In</v-btn>

      <div>
        <v-divider class="mt-5 mb-4"></v-divider>

        <small v-if="false" class="d-block">Forgot Password? <router-link to="/recover">Recover</router-link></small>
        <small class="d-block text-muted"><span class="grey--text">Don't have an account?</span> <router-link to="/signup">Register Now</router-link></small>
      </div>
    </v-form>
  </div>
</template>

<script>
import authApi from '../api/auth';
import { emailRules, passwordRules, requiredRules } from '../js/validators';
import {mapMutations} from "vuex";

export default {
  name: 'SignInForm',

  computed: {

  },

  mounted() {
    if (this.$route.query?.email) {
      this.email = this.$route.query?.email;
    }
  },

  methods: {
    ...mapMutations(['setLoading']),
    resetFormValidation() {
      this.errors.email = '';
      this.errors.password = '';
    },
    handleFormSubmit(e) {
      this.resetFormValidation();

      this.$nextTick(async function() {
        if (!this.$refs.form.validate() || !this.valid) { return }

        this.setLoading(true);

        const { status, errors = {}, message } = await authApi.signin.call(this,{
          email: this.email,
          password: this.password
        });

        if (!status) {
          this.setLoading(false);
          return;
        }

        if (this.$route.path !== '/rotator') {
          this.$router.push('/rotator');
        }
      });
    }
  },

  data: () => ({
    valid: false,
    showPassword: false,

    password: '',
    passwordRules: [...requiredRules, ...passwordRules],

    email: '',
    emailRules: [...requiredRules, ...emailRules],

    errors: { email: '', password: '' }
  }),
}
</script>

<style lang="scss">

</style>
